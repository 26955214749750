import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Spin,
  Table,
  Empty,
  Checkbox,
  Menu,
  Dropdown,
  Tooltip,
  Divider,
} from "antd";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ProductionMenu from "./ProductionMenu";
import axios from "axios";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import dayjs from "dayjs";
import {
  FilterFilled,
  UploadOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import ExcelJS from "exceljs";
import { render } from "@testing-library/react";

const ProductReport = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");

  const [ProductForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ProductLoading, setProductLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTable, setShowTable] = useState(false); // New state variable
  const defaultStartDate = dayjs().format("YYYY-MM-DD");
  const defaultEndDate = dayjs().format("YYYY-MM-DD");
  const [listOfProducts, setListOfProducts] = useState([]);
  const [SubCategory, setSubCategory] = useState([]);
  const [newType, setNewType] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);

  const fetchProductTransactions = async (FormData) => {
    setLoading(true);
    setError(null);

    const effectiveStartDate =
      FormData.period === "custom" && FormData.startDate
        ? dayjs(FormData.startDate).format("YYYY-MM-DD")
        : defaultStartDate;

    const effectiveEndDate =
      FormData.period === "custom" && FormData.endDate
        ? dayjs(FormData.endDate).format("YYYY-MM-DD")
        : defaultEndDate;

    try {
      const api_config = {
        method: "get",
        url: `${
          Config.base_url
        }Reports/GetProductReportBy/${CompanyID}?productName=${
          selectedProduct ? selectedProduct.name : ""
        }&startDate=${effectiveStartDate}&endDate=${effectiveEndDate}&period=${
          FormData.period
        }&includeAllProducts=${FormData.product === "all"}&category=${
          FormData.category
        }`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };
      const response = await axios(api_config);
      setTransactions(response.data);
      console.log("Product transactions response:", response);
      setShowTable(true);
    } catch (err) {
      console.error("Error fetching Product transactions:", err);
      setError("Failed to load transactions.");
    } finally {
      setLoading(false);
    }
  };

  const category = ProductForm.getFieldValue("category");
  const type = ProductForm.getFieldValue("type");

  useEffect(() => {
    document.title = "Product Report";
    fetchProducts();
    GetCategoryDropdownData();
    GetTypeDropdownData();
  }, [category, type]);

  const fetchProducts = async () => {
    setProductLoading(true);
    const api_config = {
      method: "get",
      url: `${
        Config.base_url
      }Product/GetBy/${CompanyID}?&pageNumber=1&pageSize=1000000&category=${
        category || ""
      }&type=${type || ""}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        console.log(response);
        setProductLoading(false);
        setListOfProducts(response.data.listofProducts || []);
      } else {
        setProductLoading(false);

        setListOfProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setProductLoading(false);

      setListOfProducts([]);
    }
  };

  const GetCategoryDropdownData = async () => {
    setCategoryLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
          `DropdownData/GetDropdownData/${CompanyID}?Type=ProductCategory`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setSubCategory(response.data.dropdownData);
        setCategoryLoading(false);
      }
    } catch (error) {
      console.error(error);
      setCategoryLoading(false);
    }
  };

  const GetTypeDropdownData = async () => {
    try {
      const response = await axios.get(
        Config.base_url +
          `DropdownData/GetDropdownData/${CompanyID}?Type=ProductType`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setNewType(response.data.dropdownData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleProductChange = (value) => {
    if (value === "all") {
      setSelectedProduct(null);
      ProductForm.setFieldsValue({
        code: null,
      });
    } else {
      const product = ListOfRecords.find((product) => product.name === value);
      setSelectedProduct(product);
      if (product) {
        ProductForm.setFieldsValue({
          product: product.name,
        });
      }
    }
  };

  const handleDateChange = (date, dateString) => {
    setStartDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleDueDateChange = (date, dateString) => {
    setEndDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Suppliers");

    // Set column headers and their widths
    sheet.columns = [
      { header: "Date", key: "date", width: 15 },
      { header: "Supplier Name", key: "supplierName", width: 30 },
      { header: "Customer Name", key: "customerName", width: 20 },
      { header: "Purchase Quantity", key: "purchaseQuantity", width: 20 },
      { header: "Purchase Weight", key: "purchaseWeight", width: 20 },
      { header: "Purchase Length", key: "purchaseLength", width: 20 },
      { header: "Sale Quantity", key: "saleQuantity", width: 20 },
      { header: "Sale Weight", key: "saleWeight", width: 20 },
      { header: "Sale Length", key: "saleLength", width: 20 },
      { header: "Default Unit", key: "defaultUnit", width: 20 },
      { header: "Balance Quantity", key: "balanceQuantity", width: 20 },
      { header: "Balance Weight", key: "balanceWeight", width: 20 },
      { header: "Balance Length", key: "balanceLength", width: 20 },
    ];

    // Add rows to the sheet
    transactions.forEach((product, index) => {
      sheet.addRow({
        sr: index + 1,
        date: product.date,
        supplierName: product.supplierName,
        customerName: product.customerName,
        purchaseQuantity: product.purchaseQuantity,
        purchaseWeight: product.purchaseWeight,
        purchaseLength: product.purchaseLength,
        saleQuantity: product.saleQuantity,
        saleWeight: product.saleWeight,
        saleLength: product.saleLength,
        defaultUnit: product.defaultUnit,
        balanceQuantity: product.balanceQuantity,
        balanceWeight: product.balanceWeight,
        balanceLength: product.balanceLength,
      });
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" }) // Format: YYYY-MM-DD HH:mm:ss
      .replace(/[^0-9]/g, ""); // Remove special characters like : and space

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `ProductSummary_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      width: 30,
      render: (_, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 30,
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      sorter: (a, b) =>
        String(a.supplierName).localeCompare(String(b.supplierName)),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      sorter: (a, b) =>
        String(a.customerName).localeCompare(String(b.customerName)),
    },
    {
      title: "Purchase Quantity",
      dataIndex: "purchaseQuantity",
      key: "purchaseQuantity",
      sorter: (a, b) => a.purchaseQuantity - b.purchaseQuantity,
    },
    {
      title: "Purchase Weight",
      dataIndex: "purchaseWeight",
      key: "purchaseWeight",
      sorter: (a, b) => a.purchaseWeight - b.purchaseWeight,
    },
    {
      title: "Purchase Length",
      dataIndex: "purchaseLength",
      key: "purchaseLength",
      sorter: (a, b) => a.purchaseLength - b.purchaseLength,
    },
    {
      title: "Sale Quantity",
      dataIndex: "saleQuantity",
      key: "saleQuantity",
      sorter: (a, b) => a.saleQuantity - b.saleQuantity,
    },
    {
      title: "Sale Weight",
      dataIndex: "saleWeight",
      key: "saleWeight",
      sorter: (a, b) => a.saleWeight - b.saleWeight,
    },
    {
      title: "Sale Length",
      dataIndex: "saleLength",
      key: "saleLength",
      sorter: (a, b) => a.saleLength - b.saleLength,
    },
    {
      title: "Default Unit",
      dataIndex: "defaultUnit",
      key: "defaultUnit",
      sorter: (a, b) =>
        String(a.defaultUnit).localeCompare(String(b.defaultUnit)),
    },
    {
      title: "Balance Quantity",
      dataIndex: "balanceQuantity",
      key: "balanceQuantity",
      sorter: (a, b) => a.balanceQuantity - b.balanceQuantity,
    },
    {
      title: "Balance Weight",
      dataIndex: "balanceWeight",
      key: "balanceWeight",
      sorter: (a, b) => a.balanceWeight - b.balanceWeight,
    },
    {
      title: "Balance Length",
      dataIndex: "balanceLength",
      key: "balanceLength",
      sorter: (a, b) => a.balanceLength - b.balanceLength,
    },
  ];

  // Manage state for selected columns (default all columns selected)
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );

  // Handle checkbox change
  const handleCheckboxChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  // Filter columns based on selected checkboxes
  const columnsToShow = columns.filter((col) =>
    selectedColumns.includes(col.key)
  );

  //   let totalAmount = 0;

  //   transactions.forEach(({ amount }) => {
  //     totalAmount += amount || 0;
  //   });

  //   return (
  //     <Table.Summary.Row>
  //       <Table.Summary.Cell colSpan={10}>Total</Table.Summary.Cell>
  //       <Table.Summary.Cell>{totalAmount.toFixed(2)}</Table.Summary.Cell>

  //       <Table.Summary.Cell>
  //         {/* Balance can be calculated if needed */}
  //       </Table.Summary.Cell>
  //     </Table.Summary.Row>
  //   );
  // };

  const printTable = () => {
    const isAllProductsSelected = selectedProduct === "All Products";

    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/antd/4.21.7/antd.min.css' />"
    );
    printWindow.document.write("</head><body style='text-align: center;'>");
    printWindow.document.write(
      "<h1 style='text-align: center;'>Product Report</h1>"
    );

    // Conditionally render Product name if "All Products" is NOT selected
    if (!isAllProductsSelected) {
      printWindow.document.write(
        "<h3>Product: " + (selectedProduct?.name || "All Products") + "</h3>"
      );
      printWindow.document.write(
        "<h3>Product Code: " + (selectedProduct?.code || "N/A") + "</h3>"
      );
    } else if (isAllProductsSelected) {
      printWindow.document.write("<h3>All Products</h3>");
    }

    if (startDate && endDate) {
      printWindow.document.write(
        "<h3>Date Range: " + startDate + " - " + endDate + "</h3>"
      );
    }

    printWindow.document.write(
      "<div style='display: flex; justify-content: center; width: 1450px'>"
    );
    printWindow.document.write(document.querySelector(".ant-table").outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const menu = (
    <Menu>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "10px",
          padding: "10px",
        }}
      >
        {columns.map((col) => (
          <div
            key={col.dataIndex}
            style={{ display: "flex", alignItems: "start" }}
          >
            <Checkbox
              checked={selectedColumns.includes(col.dataIndex)}
              onChange={(e) => {
                const newSelected = e.target.checked
                  ? [...selectedColumns, col.dataIndex]
                  : selectedColumns.filter((c) => c !== col.dataIndex);
                setSelectedColumns(newSelected);
              }}
            >
              {col.title}
            </Checkbox>
          </div>
        ))}
      </div>
    </Menu>
  );

  const handleSelectChange = (value) => {
    console.log(value);
    const product = listOfProducts.find((product) => product.name === value);
    setSelectedProduct(product);
  };

  return (
    <>
      <div id="sub-menu-wrap">
        <h5>Product</h5>
        <ProductionMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/products/manage">
                <ArrowLeftIcon />
              </NavLink>
              Product Report
            </h3>
            {showTable && transactions.length > 0 && (
              <div className="header-actions">
                <Button
                  type="dashed"
                  onClick={handleExport}
                  icon={<UploadOutlined />}
                >
                  Export Report
                </Button>
                <Button
                  type="default"
                  onClick={printTable}
                  icon={<PrinterOutlined />}
                >
                  Print Report
                </Button>
              </div>
            )}
          </div>
          <div className="filters-wrap">
            <Form onFinish={fetchProductTransactions} form={ProductForm}>
              <Form.Item name="category">
                <Select
                  style={{
                    width: 150,
                  }}
                  showSearch // Enable search functionality
                  filterOption={
                    (input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase()) // Use option.label instead of option.children
                  }
                  placeholder="Category"
                  options={SubCategory.map((fieldThreeitem) => ({
                    label: fieldThreeitem.name,
                    value: fieldThreeitem.name,
                  }))}
                  onSelect={fetchProducts}
                />
              </Form.Item>
              <Form.Item name="type">
                <Select
                  style={{
                    width: 150,
                  }}
                  showSearch // Enable search functionality
                  filterOption={
                    (input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase()) // Use option.label instead of option.children
                  }
                  placeholder="Type"
                  options={newType.map((fieldThreeitem) => ({
                    label: fieldThreeitem.name,
                    value: fieldThreeitem.name,
                  }))}
                  onSelect={fetchProducts}
                />
              </Form.Item>
              <Form.Item
                name="product"
                rules={[
                  {
                    required: true,
                    message: "Please select a product",
                  },
                ]}
              >
                <Select
                  style={{ width: "300px" }}
                  placeholder="Select Product"
                  onSelect={handleSelectChange}
                  showSearch // Enable search functionality
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Select.Option value="all">All Products</Select.Option>
                  {listOfProducts.map((account) => (
                    <Select.Option key={account.id} value={account.name}>
                      {account.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="code" label="Code" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                name="period"
                onChange={(value) => setPeriod(value)}
                style={{ width: "150px" }}
                initialValue="last60Days"
              >
                <Select placeholder="Date Range">
                  <Select.Option value="all">All Dates</Select.Option>
                  <Select.Option value="custom">Custom</Select.Option>
                  <Select.Option value="today">Today</Select.Option>
                  <Select.Option value="week">This Week</Select.Option>
                  <Select.Option value="month">This Month</Select.Option>
                  <Select.Option value="last60Days">Last 60 Days</Select.Option>
                  <Select.Option value="year">This Year</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="startDate">
                <DatePicker
                  value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                  onChange={handleDateChange}
                />
              </Form.Item>
              <Form.Item name="endDate">
                <DatePicker
                  value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                  onChange={handleDueDateChange}
                />
              </Form.Item>

              <Button type="primary" htmlType="submit" loading={loading}>
                Run Report
              </Button>
            </Form>
          </div>

          {showTable && transactions.length > 0 && (
            <>
              <div className="filter-dropdown">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="top"
                  overlayStyle={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  <span>
                    <Tooltip>
                      <FilterFilled
                        style={{
                          fontSize: "24px",
                          color: "#1890ff",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "#46484B",
                        }}
                      />
                    </Tooltip>
                  </span>
                </Dropdown>
              </div>
              <div style={{ textAlign: "center", marginBottom: "16px" }}>
                <h3>
                  Product:{" "}
                  {selectedProduct ? selectedProduct.name : "All Products"}
                </h3>
                {selectedProduct && (
                  <h3>Product Code: {selectedProduct.code}</h3>
                )}
              </div>
              <div
                style={{ overflow: "auto", maxWidth: "1150px", width: "100%" }}
              >
                <Table
                  columns={columnsToShow}
                  dataSource={transactions}
                  rowKey={(record) => record.id || Math.random()}
                  pagination={false}
                />
              </div>
            </>
          )}

          {showTable && transactions.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </>
  );
};

export default ProductReport;
