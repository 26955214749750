import React, { useState, useEffect } from "react";
import { Button, Form, Select, DatePicker, Table, Empty, Divider } from "antd";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import Config from "../../Config";
import ProductionMenu from "./ProductionMenu";
import SubMenuToggle from "../Common/SubMenuToggle";
import ProductsDropdown from "../Common/ProductsDropdown";
import { PrinterOutlined, UploadOutlined } from "@ant-design/icons";
import ExcelJS from "exceljs";

const ProductSummary = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");

  const [ProductForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState("all");
  const [date, setDate] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const defaultDate = dayjs().format("YYYY-MM-DD");
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [SubCategory, setSubCategory] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [newType, setNewType] = useState([]);
  const [listOfProducts, setListOfProducts] = useState([]);
  const [productLoading, setProductLoading] = useState(false);

  const category = ProductForm.getFieldValue("category");
  const type = ProductForm.getFieldValue("type");

  useEffect(() => {
    document.title = "Products Summary";
    GetCategoryDropdownData();
    GetTypeDropdownData();
    fetchProducts();
  }, [category, type]);

  const fetchSupplierTransactions = async (formData) => {
    setLoading(true);
    setError(null);

    const effectiveDate =
      formData.period === "custom" && formData.date
        ? dayjs(formData.date).format("YYYY-MM-DD")
        : defaultDate;

    try {
      const api_config = {
        method: "get",
        url: `${
          Config.base_url
        }Reports/GetProductSummaryReportBy/${CompanyID}?productName=${
          selectedProduct ? selectedProduct.name : ""
        }&period=${
          formData.period
        }&reportDate=${effectiveDate}&includeAllProducts=${
          formData.name === "all"
        }&category=${category || ""}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };
      const response = await axios(api_config);
      setTransactions(response.data.products || []);
      setShowTable(true);
    } catch (err) {
      console.error("Error fetching product transactions:", err);
      // setError("Failed to load transactions.");
      setTransactions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setDate(date ? dayjs(date).format("YYYY-MM-DD") : null);
  };

  const fetchProducts = async () => {
    setProductLoading(true);
    const api_config = {
      method: "get",
      url: `${
        Config.base_url
      }Product/GetBy/${CompanyID}?&pageNumber=1&pageSize=1000000&category=${
        category || ""
      }&type=${type || ""}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        console.log(response);
        setProductLoading(false);
        setListOfProducts(response.data.listofProducts || []);
      } else {
        setProductLoading(false);

        setListOfProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setProductLoading(false);

      setListOfProducts([]);
    }
  };
  const GetCategoryDropdownData = async () => {
    setCategoryLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
          `DropdownData/GetDropdownData/${CompanyID}?Type=ProductCategory`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setSubCategory(response.data.dropdownData);
        setCategoryLoading(false);
      }
    } catch (error) {
      console.error(error);
      setCategoryLoading(false);
    }
  };

  const GetTypeDropdownData = async () => {
    try {
      const response = await axios.get(
        Config.base_url +
          `DropdownData/GetDropdownData/${CompanyID}?Type=ProductType`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setNewType(response.data.dropdownData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Product Code",
      dataIndex: "productCode",
      key: "productCode",
      sorter: (a, b) => a.productCode - b.productCode,
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => record.name.split(" (")[0],
      sorter: (a, b) => String(a.name).localeCompare(String(b.name)),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => String(a.category).localeCompare(String(b.category)),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => String(a.type).localeCompare(String(b.type)),
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
      sorter: (a, b) => a.amount - b.amount,
    },
  ];

  const summary = () => {
    let totalAmount = 0;

    transactions.forEach(({ amount }) => {
      totalAmount += amount || 0;
    });

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={7}>Total</Table.Summary.Cell>
        <Table.Summary.Cell>{totalAmount.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>
          {/* Balance can be calculated if needed */}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const printTable = () => {
    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/antd/4.21.7/antd.min.css' />"
    );
    printWindow.document.write(
      "<h1 style='text-align: center;'>Products Summary</h1>"
    );

    if (date) {
      printWindow.document.write(`<h3>Date: ${date}</h3>`);
    }

    printWindow.document.write(
      "<div style='display: flex; justify-content: center;'>"
    );
    printWindow.document.write(document.querySelector(".ant-table").outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const handleSelectChange = (value) => {
    console.log(value);
    const product = listOfProducts.find((product) => product.name === value);
    setSelectedProduct(product);
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Suppliers");

    // Set column headers and their widths
    sheet.columns = [
      { header: "Product Code", key: "productCode", width: 15 },
      { header: "Product Name", key: "name", width: 30 },
      { header: "Category", key: "category", width: 20 },
      { header: "Type", key: "type", width: 20 },
      { header: "Quantity", key: "quantity", width: 20 },
      { header: "Rate", key: "rate", width: 20 },
      { header: "Amount", key: "amount", width: 20 },
    ];

    // Add rows to the sheet
    transactions.forEach((product, index) => {
      sheet.addRow({
        sr: index + 1,
        productCode: product.productCode,
        name: product.name,
        category: product.category,
        type: product.type,
        quantity: product.quantity,
        rate: product.rate,
        amount: product.amount,
      });
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" }) // Format: YYYY-MM-DD HH:mm:ss
      .replace(/[^0-9]/g, ""); // Remove special characters like : and space

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `ProductSummary_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <div id="sub-menu-wrap">
        <h5>Production</h5>
        <ProductionMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/products/manage">
                <ArrowLeftIcon />
              </NavLink>
              Products Summary
            </h3>
            {showTable && transactions.length > 0 && (
              <div className="header-actions">
                <Button
                  type="dashed"
                  onClick={handleExport}
                  icon={<UploadOutlined />}
                >
                  Export Report
                </Button>
                <Button
                  type="default"
                  onClick={printTable}
                  icon={<PrinterOutlined />}
                >
                  Print Report
                </Button>
              </div>
            )}
          </div>

          <div className="filters-wrap">
            <Form onFinish={fetchSupplierTransactions} form={ProductForm}>
              <Form.Item name="category">
                <Select
                  style={{
                    width: 150,
                  }}
                  showSearch // Enable search functionality
                  filterOption={
                    (input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase()) // Use option.label instead of option.children
                  }
                  placeholder="Category"
                  options={SubCategory.map((fieldThreeitem) => ({
                    label: fieldThreeitem.name,
                    value: fieldThreeitem.name,
                  }))}
                  onSelect={fetchProducts}
                />
              </Form.Item>
              <Form.Item name="type">
                <Select
                  style={{
                    width: 150,
                  }}
                  showSearch // Enable search functionality
                  filterOption={
                    (input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase()) // Use option.label instead of option.children
                  }
                  placeholder="Type"
                  options={newType.map((fieldThreeitem) => ({
                    label: fieldThreeitem.name,
                    value: fieldThreeitem.name,
                  }))}
                  onSelect={fetchProducts}
                />
              </Form.Item>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please select a product",
                  },
                ]}
              >
                <Select
                  style={{ width: "300px" }}
                  placeholder="Select Product"
                  onSelect={handleSelectChange}
                  showSearch // Enable search functionality
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Select.Option value="all">All Products</Select.Option>
                  {listOfProducts.map((account) => (
                    <Select.Option key={account.id} value={account.name}>
                      {account.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="period" initialValue="last60Days">
                <Select
                  onChange={setPeriod}
                  style={{ width: "150px" }}
                  placeholder="Date Range"
                >
                  <Select.Option value="all">All Dates</Select.Option>
                  <Select.Option value="custom">Custom</Select.Option>
                  <Select.Option value="today">Today</Select.Option>
                  <Select.Option value="week">This Week</Select.Option>
                  <Select.Option value="month">This Month</Select.Option>
                  <Select.Option value="last60Days">Last 60 Days</Select.Option>
                  <Select.Option value="year">This Year</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="reportDate">
                <DatePicker
                  value={date ? dayjs(date, "YYYY-MM-DD") : null}
                  style={{ width: "100%" }}
                  onChange={handleDateChange}
                />
              </Form.Item>

              <Button type="primary" htmlType="submit" loading={loading}>
                Run Report
              </Button>
            </Form>
          </div>

          {showTable && transactions.length > 0 && (
            <>
              <Table
                columns={columns}
                dataSource={transactions}
                rowKey="id"
                summary={summary}
                pagination={false}
              />
            </>
          )}

          {showTable && transactions.length <= 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductSummary;
