import React, { useEffect } from "react";

import Logo from "../../assets/images/logo/dark-h.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Button, Col, Form, Input, Row, Upload, Dropdown } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";

function Dashboard(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const FullName = localStorage.getItem("Full_Name");
  const CompanyID = localStorage.getItem("CompanyID");

  const navigate = useNavigate();

  useEffect(() => {
    const CompanyName = localStorage.getItem("CompanyName");
    if (!AccessKey || !UserID || !FullName) {
      navigate("/");
    }
  }, []);

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/login");
  };
  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  const items = [
    {
      key: "1",
      label: (
        <a rel="noopener noreferrer" href="#">
          Edit Profile
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a rel="noopener noreferrer" href="#" onClick={handleSignOut}>
          Sign Out
        </a>
      ),
      // icon: <SmileOutlined />,
    },
  ];

  return (
    <>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header"><SubMenuToggle />
            <h3 className="page-title">Dashboard</h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
