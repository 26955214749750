import { Form, Input, Table, Button, Modal, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  PlusOutlined,
  PrinterOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { Link, NavLink } from "react-router-dom";
import BanksMenu from "./BanksMenu";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import axios from "axios";
import ExcelJS from "exceljs";

function AccountBalance() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const CompanyID = localStorage.getItem("CompanyID");
  const FYear = localStorage.getItem("DefaultFYear");

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // pagination
  const [ListOfBank, setListOfBank] = useState([]);
  const [BankAccount, setBankAccount] = useState([]);

  const [ModalForm] = Form.useForm();

  useEffect(() => {
    document.title = "Account Balances";
    fetchBankAccounts();
    GetBankAccountsData();
  }, []);

  const fetchBankAccounts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
          `Bank/GetAccountBalances/${CompanyID}?level=3&accountCode=50108`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      //console.log(response.data);
      setListOfBank(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const AddNewBankAccount = async (FormData) => {
    const data = {
      ...FormData,
      accountCode: (
        parseInt(BankAccount[BankAccount.length - 1].accountCode) + 1
      ).toString(),
      accountDescription: FormData.bankAccount,
      iLevel: 3,
      remarks: FormData.bankAccount + FormData.description,
      year: FYear,
      isActive: true,
      isDeleted: false,
      companyID: CompanyID,
    };

    const encodedbankAccount = encodeURIComponent(FormData.bankAccount);

    const apiUrl =
      Config.base_url +
      `AccountMain/GetBy/${CompanyID}?accountCode=50108&accountName=${encodedbankAccount}&level=3`;
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${AccessKey}`,
      },
    });

    const length = response.data.listofAccounts.length;
    if (length > 0) {
      message.error("Bank Account with same name already exist");
      ModalForm.resetFields();
      return;
    }

    try {
      const response = await axios.post(
        Config.base_url + `AccountMain/AddAccount`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        message.success(response.data.status_message);
        // Reset the form field
        ModalForm.resetFields();
        fetchBankAccounts();
      }
    } catch (error) {
      console.error(error);
      message.error("Network Error..");
    } finally {
      setOpen(false);
    }
  };

  const GetBankAccountsData = async () => {
    try {
      const response = await axios.get(
        Config.base_url +
          `AccountMain/GetBy/${CompanyID}?accountCode=50108&level=3`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setBankAccount(response.data.listofAccounts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Bank",
      dataIndex: "accountDescription",
      key: "accountDescription",
      sorter: (a, b) =>
        String(a.accountDescription).localeCompare(
          String(b.accountDescription)
        ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      sorter: (a, b) => a.balance - b.balance,
    },
  ];

  const handleCancel = () => {
    setOpen(false);
  };
  const ShowModal = () => {
    setOpen(true);
  };

  const summary = () => {
    let bankTotal = 0;

    ListOfBank.forEach(({ balance }) => {
      bankTotal += balance || 0;
    });

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
        <Table.Summary.Cell>{bankTotal.toFixed(2)}</Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Account Balances");

    // Set column headers and their widths
    sheet.columns = [
      { header: "Bank Name", key: "accountDescription", width: 30 },
      { header: "Balance", key: "balance", width: 30 },
    ];

    // Add rows to the sheet
    ListOfBank.forEach((bank, index) => {
      sheet.addRow({
        sr: index + 1,
        accountDescription: bank.accountDescription,
        balance: bank.balance,
      });
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" }) // Format: YYYY-MM-DD HH:mm:ss
      .replace(/[^0-9]/g, ""); // Remove special characters like : and space

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `AccountBalances_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const printTable = () => {
    const printWindow = window.open("", "", "height=600,width=800");

    // Add Ant Design stylesheet to the new window for table styling
    printWindow.document.write(
      "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/antd/4.21.7/antd.min.css' />"
    );

    printWindow.document.write("</head><body style='text-align: center;'>");

    // Add a title for the report
    printWindow.document.write(
      "<h1 style='text-align: center;'>Account Balances</h1>"
    );

    // Always print the entire table, not depending on selectedCustomer
    printWindow.document.write(
      "<div style='display: flex; justify-content: center;'>"
    );

    // Extract the table's HTML and print it
    const tableHTML = document.querySelector(".ant-table").outerHTML;
    printWindow.document.write(tableHTML);

    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  return (
    <>
      {/* Add New Bank Account */}
      <Modal
        title="Bank Account New"
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" form={ModalForm} onFinish={AddNewBankAccount}>
          {/* Nominal Account */}
          <Form.Item
            label="Bank Account"
            name="bankAccount"
            rules={[
              {
                required: true,
                message: "Please input the bank account!",
              },
            ]}
          >
            <Input
              onFocus={(e) => e.target.select()}
              placeholder="Bank Account"
            />
          </Form.Item>

          {/* Code */}
          <Form.Item label="Code" name="code">
            <Input
              onFocus={(e) => e.target.select()}
              placeholder="Code"
              disabled
            />
          </Form.Item>

          {/* Description */}
          <Form.Item label="Description" name="description">
            <Input
              onFocus={(e) => e.target.select()}
              placeholder="Description"
            />
          </Form.Item>

          {/* Form Actions */}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              type="default"
              style={{ marginLeft: "8px" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div id="sub-menu-wrap">
        <h5>Bank</h5>
        <BanksMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <SubMenuToggle />
            <h3 className="page-title">Account Balances</h3>
            <div className="header-actions">
              <Button
                type="default"
                onClick={printTable}
                icon={<PrinterOutlined />}
              >
                Print
              </Button>
              <Button
                type="dashed"
                onClick={handleExport}
                icon={<UploadOutlined />}
              >
                Export
              </Button>
              <Button
                type="primary"
                onClick={ShowModal}
                icon={<PlusOutlined />}
              >
                New Account
              </Button>
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={ListOfBank}
            size="small"
            loading={loading}
            summary={summary}
            pagination={{ defaultPageSize: 30 }}
          />
        </div>
      </div>
    </>
  );
}
export default AccountBalance;
