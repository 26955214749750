import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import Logo from "../../assets/images/logo/dark-h.svg";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { ChevronDownIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import {
  UsersIcon,
  CurrencyDollarIcon,
  Cog8ToothIcon,
  TagIcon,
  ShoppingCartIcon,
  ChartPieIcon,
} from "@heroicons/react/24/outline";
import { Dropdown, Tooltip, Image } from "antd";
import SideMenu from "./SideMenu";
import LeftMenu from "./LeftMenu";

function Main(props) {
  const [roleName, setRoleName] = useState([]);

  const AccessKey = localStorage.getItem("AccessKey");
  const FullName = localStorage.getItem("Full_Name");
  const CompanyName = localStorage.getItem("CompanyName");
  const UserID = localStorage.getItem("ID");
  const CompanyID = localStorage.getItem("CompanyID");

  useEffect(() => {
    const fetchRoles = async () => {
      const response = await axios.get(
        Config.base_url +
          `Account/GetRolesByEmail/${UserID}?companyID=${CompanyID}`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      setRoleName(response.data);

      const rolesObject = response.data.reduce((acc, item) => {
        acc[item.role.id] = true;
        return acc;
      }, {});

      const roleNames = Object.keys(rolesObject);

      // Store the role names in localStorage
      localStorage.setItem("roles", JSON.stringify(roleNames));
    };
    fetchRoles();
  }, [UserID, CompanyID]);

  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  const items = [
    {
      key: "1",
      label: (
        <Link rel="noopener noreferrer" to="/profile">
          Edit Profile
        </Link>
      ),
    },
    {
      key: "2",
      label: <NavLink to="/change-company">Change Company</NavLink>,
    },
    {
      key: "3",
      label: (
        <a rel="noopener noreferrer" href="#" onClick={handleSignOut}>
          Sign Out
        </a>
      ),
      // icon: <SmileOutlined />,
    },
  ];

  const getFirstChar = (str) => {
    const firstChars = str
      .split(" ")
      .map((word) => word[0])
      .join("");
    return firstChars;
  };

  const toggleClass = (id, className = "active") => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.toggle(className);
    }
  };

  // Usage in click handlers
  const handleClick = () => {
    toggleClass("side-menu");
    toggleClass("overlaymenu");
    toggleClass("body");
    toggleClass("hamburger");
  };

  const RemoveActiveClass = (id, className = "active") => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.remove(className);
    }
  };

  // Usage in click handlers
  const closeMenu = () => {
    RemoveActiveClass("side-menu");
    RemoveActiveClass("overlaymenu");
    RemoveActiveClass("body");
    RemoveActiveClass("hamburger");
    RemoveActiveClass("sub-menu-wrap");
  };

  return (
    <>
      <main id="main">
        <header id="dashboard-header">
          <div>
            <div className="header-left">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <img src={Logo} />
                <span style={{ fontSize: "15px", color: "#324F94" }}>
                  vers 2.7.1{" "}
                </span>
              </Link>

              <div className="working-company">
                <Link to={"/edit-company"} className="add-company">
                  {CompanyName}{" "}
                  {roleName.map((item) => (
                    <span key={item.id}>({item.role.name})</span>
                  ))}
                </Link>
                <NavLink to="/register-company">
                  <PlusCircleIcon />
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            <Dropdown
              menu={{
                items,
              }}
            >
              <div className="button-user-in cursor-pointer">
                <div className="short-name">
                  {props.image ? (
                    <Image
                      width="100%"
                      height={"100%"}
                      src={props.image}
                      preview={false}
                      alt="Uploaded"
                      className="rounded-pill"
                    />
                  ) : (
                    getFirstChar(FullName)
                  )}
                </div>
                <span>{FullName}</span>
                <ChevronDownIcon />
              </div>
            </Dropdown>
          </div>
          <div id="hamburger" className="hamburger" onClick={handleClick}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </header>
        <div onClick={closeMenu} id="overlaymenu"></div>
        <div className="wrapper">
          <LeftMenu />
          <div id="right_side" className="right-side">
            <Outlet />
          </div>
        </div>
      </main>
    </>

    // <main id="main">

    //     <section className="layout-wrap">
    //         <SideMenu />
    //         <div className="layout-content">
    //             <Outlet />
    //         </div>
    //     </section>
    // </main>
  );
}

export default Main;
